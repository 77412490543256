import { Card } from '@/shared/components';
import { useBoostPoolInfo } from '@/shared/hooks';
import { type BoostPoolInfoProps } from '@/shared/hooks/useBoostPoolInfo';
import { ChartIcon, DropletIcon, MoneyBagIcon, PercentageIcon } from '@/shared/icons/large';
import { apyToText, chainflipAssetMap, formatUsdValue } from '@/shared/utils';
import { TrendingDownIcon, TrendingUpIcon } from '../../icons/small';

export const BoostPoolCards = ({
  asset,
  feeTier,
  account,
  className,
}: BoostPoolInfoProps & { className?: string }) => {
  const { liquidity, liquidityUsd, volume, volumeUsd, fees, feesUsd, apy } = useBoostPoolInfo({
    feeTier,
    asset,
    account,
  });

  const skeletonLineWidth = 100;
  const assetSymbol = chainflipAssetMap[asset].symbol;

  const apy24hrDelta = apy && apy.last24h ? apy.current - apy.last24h : 0;
  const apyDeltaNegative = apy24hrDelta < 0;

  return (
    <>
      <Card
        skeletonLineWidth={skeletonLineWidth}
        loading={false}
        icon={<PercentageIcon className="text-cf-light-2" />}
        title="APY"
        tooltip="Annual Percentage Yield (APY) estimates the annual returns in a boost pool and fee tier. We take hourly snapshots of earned fees and boost liquidity, averaged over 72h, and project it over a year"
        className={className}
        footer={
          apy24hrDelta !== 0 && (
            <div className="flex items-center gap-x-1">
              <span className="text-cf-light-2">Last 24H</span>
              <div className="flex items-center gap-x-1">
                {apyDeltaNegative ? (
                  <TrendingDownIcon className="text-cf-red-2" />
                ) : (
                  <TrendingUpIcon className="text-cf-green-3" />
                )}
                <span className={apyDeltaNegative ? 'text-cf-red-2' : 'text-cf-green-3'}>
                  {!apyDeltaNegative && '+'}
                  {apy24hrDelta && apy24hrDelta.toFixed(4)}%
                </span>
              </div>
            </div>
          )
        }
      >
        <div className="-my-0.5 text-[32px]">{apyToText(apy?.current)}</div>
      </Card>
      <Card
        skeletonLineWidth={skeletonLineWidth}
        loading={liquidity === null}
        icon={<DropletIcon className="text-cf-light-2" />}
        title="Boost Liquidity"
        footer="Current"
        tooltip={`The combined USD value of ${
          account ? 'your' : 'all'
        } boost positions in this pool and fee tier`}
        className={className}
      >
        <div className="flex flex-wrap items-center gap-x-1.5">
          <span className="text-20 text-white">
            {liquidity?.toPreciseFormattedString()} {assetSymbol}
          </span>
          <span className="font-aeonikRegular text-12 text-cf-light-2">
            {formatUsdValue(liquidityUsd)}
          </span>
        </div>
      </Card>
      <Card
        skeletonLineWidth={skeletonLineWidth}
        loading={fees === null}
        icon={<MoneyBagIcon className="w-[22px] text-cf-light-2" />}
        title="Earned Fees"
        tooltip={`The cumulative USD value of ${
          account ? 'your' : ''
        } earned fees in this pool and fee tier`}
        footer="All time"
        className={className}
      >
        <div className="flex flex-wrap items-center gap-x-1.5">
          <span className="text-20 text-white">
            {fees?.toPreciseFormattedString()} {assetSymbol}
          </span>
          <span className="text-12 text-cf-light-2">{formatUsdValue(feesUsd)}</span>
        </div>
      </Card>
      {!account && (
        <Card
          skeletonLineWidth={skeletonLineWidth}
          loading={volume === null}
          icon={<ChartIcon className="text-cf-light-2" />}
          title="Volume"
          tooltip="The cumulative USD value of deposits boosted by the liquidity in this pool and fee tier"
          footer="All time"
          className={className}
        >
          <div className="flex flex-wrap items-center gap-x-1.5">
            <span className="text-20 text-white">
              {volume?.toPreciseFormattedString()} {assetSymbol}
            </span>
            <span className="text-12 text-cf-light-2">{formatUsdValue(volumeUsd)}</span>
          </div>
        </Card>
      )}
    </>
  );
};
