import { useMemo } from 'react';
import NextLink from 'next/link';
import classNames from 'classnames';
import { type LiquidityBalanceEventFragment } from '@/shared/graphql/generated/graphql';
import useRemotePagination from '@/shared/hooks/useRemotePagination';
import ContractSuccess from '@/shared/icons/flip-ui-kit/large/ContractSuccess';
import { CheckCircleIcon } from '@/shared/icons/large';
import { getLiquidityBalanceEventsQuery } from '@/shared/queries/lp';
import { chainflipAssetMap, formatUsdValue, TokenAmount } from '@/shared/utils';
import { assetConstants } from '@/shared/utils/chainflip';
import Lottie from '@/shared/utils/Lottie';
import inProgressJson from '@/shared/utils/lotties/in-progress.json';
import { Link } from '../atoms/Link';
import Button from '../flip-ui-kit/Button';
import ChainPill from '../flip-ui-kit/ChainPill';
import AgeWithTooltip from '../molecules/AgeWithTooltip';
import { TableV2 } from '../molecules/TableV2';
import ChainTokenLogo from '../tokens/ChainTokenLogo';

export const ViewExtrinsicButton = ({
  blockId,
  indexInBlock,
  blockExplorerUrl,
}: {
  blockId: number;
  indexInBlock: number;
  blockExplorerUrl?: string;
}) => {
  const path = `/extrinsics/${blockId}-${indexInBlock}`;

  const href = blockExplorerUrl ? new URL(path, blockExplorerUrl) : path;

  return (
    <NextLink href={href} target={blockExplorerUrl ? '_blank' : undefined}>
      <Button type="secondary-standard" size="small" className="max-w-fit space-x-2">
        <div>
          <ContractSuccess width={16} height={16} className="text-cf-red-2" />
        </div>
        <span className="text-12 text-cf-light-3">View Extrinsic</span>
      </Button>
    </NextLink>
  );
};

const BalanceEventRow = ({
  event: { asset, amount, depositChannel: channel, valueUsd, event, broadcast },
  blockExplorerUrl,
}: {
  event: OptionalizeKey<LiquidityBalanceEventFragment, 'valueUsd'>;
  blockExplorerUrl?: string;
}) => {
  const tokenAmount = TokenAmount.fromAsset(BigInt(amount), asset);
  const depositChannelId =
    channel && `${channel.issuedBlockId}-${channel.chain}-${channel.channelId}`;

  return (
    <tr>
      <td>
        <div className="flex items-center gap-x-2">
          <ChainTokenLogo
            token={{
              symbol: chainflipAssetMap[asset].symbol,
              chain: chainflipAssetMap[asset].chain,
            }}
            size="medium"
            displayChainLogo={false}
          />{' '}
          {chainflipAssetMap[asset].symbol}
        </div>
      </td>
      <td>
        <ChainPill chain={assetConstants[asset].chain} />
      </td>
      <td
        className={classNames(
          'whitespace-nowrap font-aeonikMono',
          depositChannelId ? 'text-cf-green-3' : 'text-cf-red-2',
        )}
      >
        {depositChannelId ? '+' : '-'}
        {tokenAmount.toFixedDisplay()} {chainflipAssetMap[asset].symbol}
      </td>
      <td className="font-aeonikMono">{formatUsdValue(valueUsd) ?? '-'}</td>
      <td className="whitespace-nowrap font-aeonikMono">
        {depositChannelId && (
          <Link
            href={
              blockExplorerUrl
                ? new URL(`/liquidity-channels/${depositChannelId}`, blockExplorerUrl).toString()
                : `/liquidity-channels/${depositChannelId}`
            }
            underline
            target={blockExplorerUrl && '_blank'}
          >
            {depositChannelId}
          </Link>
        )}
      </td>
      <td className="min-w-[125px] whitespace-nowrap text-12 text-cf-light-2">
        <div className="flex items-center gap-1">
          {event && (!broadcast || broadcast.broadcastSuccessEventId) ? (
            <>
              <CheckCircleIcon className="text-cf-green-1" width={16} height={16} />
              <span>Success</span>
            </>
          ) : (
            <>
              <Lottie
                className="w-[20px]"
                as="span"
                autoplay
                speed={1}
                loop
                animationData={inProgressJson}
              />
              <span className="text-cf-light-2">In progress</span>
            </>
          )}
        </div>
      </td>
      <td className="whitespace-nowrap">
        {event && event.extrinsic && (
          <ViewExtrinsicButton
            blockId={event.extrinsic.blockId}
            indexInBlock={event.extrinsic.indexInBlock}
            blockExplorerUrl={blockExplorerUrl}
          />
        )}
      </td>
      <td className="whitespace-nowrap text-right font-aeonikMono text-12 text-cf-light-2">
        {event && <AgeWithTooltip timestamp={event.block.timestamp} />}{' '}
      </td>
    </tr>
  );
};

type OptionalizeKey<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export const BalanceEventsTable = ({
  accountIdSs58,
  blockExplorerUrl,
  pendingDeposits,
}: {
  accountIdSs58?: string;
  blockExplorerUrl?: string;
  pendingDeposits?: OptionalizeKey<LiquidityBalanceEventFragment, 'valueUsd'>[];
}): JSX.Element => {
  const {
    data: eventsPaginated,
    isLoading: eventsLoading,
    error,
    paginationControls,
  } = useRemotePagination(getLiquidityBalanceEventsQuery, {
    variables: { idSs58: accountIdSs58! },
    useQueryParam: true,
    totalCount: (data) => data.account?.lp?.events.totalCount ?? 0,
    enabled: Boolean(accountIdSs58),
  });

  const memoizedRows = useMemo(() => {
    let events = (eventsPaginated?.account?.lp?.events.edges ?? []) as {
      node: OptionalizeKey<LiquidityBalanceEventFragment, 'valueUsd'>;
    }[];

    if (
      pendingDeposits &&
      pendingDeposits.length > 0 &&
      paginationControls.pageCounter.atBeginning
    ) {
      events = pendingDeposits.map((node) => ({ node })).concat(events);
    }

    return events.map(({ node }) => (
      <BalanceEventRow key={node.eventId} event={node} blockExplorerUrl={blockExplorerUrl} />
    ));
  }, [eventsPaginated, pendingDeposits]);

  if (error) throw error;
  return (
    <TableV2
      columns={[
        'Asset',
        'Chain',
        'Amount',
        'Value',
        'Deposit Channel',
        'Status',
        'Extrinsic',
        { alignment: 'right', name: 'Age' },
      ]}
      rows={memoizedRows}
      isLoading={eventsLoading}
      paginationControls={paginationControls}
    />
  );
};
