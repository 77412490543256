import { FLIP_SYMBOL } from '@/shared/utils';
import Pill from '../flip-ui-kit/Pill';
import { Tooltip } from '../molecules/Tooltip';

type Key = 'isCurrentAuthority' | 'isCurrentBackup' | 'isBidding' | 'isQualified' | 'isKeyholder';

export const ValidatorBadges = ({
  cacheValidator,
  keys,
  halfOpacity = false,
}: {
  cacheValidator: ({ [K in Key]: boolean } & { apyBp?: number | null | undefined }) | undefined;
  keys?: Key[];
  halfOpacity?: boolean;
}) => {
  if (!cacheValidator) return null;

  const isBackupEarningRewards = cacheValidator.isCurrentBackup && Boolean(cacheValidator.apyBp);

  const validator = {
    ...cacheValidator,
    isCurrentBackup: isBackupEarningRewards,
  };

  const opacity = halfOpacity ? 'opacity-50' : undefined;

  return (
    <>
      {(
        [
          {
            key: 'isCurrentAuthority' as const,
            color: 'green',
            tooltip: (
              <div>
                Authorities are responsible for block authorship, consensus, witnessing, threshold
                signing and transaction broadcasting. In return, they earn FLIP.{' '}
                <a
                  className="underline"
                  target="_blank"
                  href="https://docs.chainflip.io/concepts/validator-network/validator-types-and-states"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </div>
            ),
          },
          {
            key: 'isCurrentBackup' as const,
            color: 'pink',
            tooltip: (
              <div>
                A Backup is among the highest bidding non-Authorities, earning {FLIP_SYMBOL} in
                return.{' '}
                <a
                  className="underline"
                  target="_blank"
                  href="https://docs.chainflip.io/concepts/validator-network/validator-types-and-states"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </div>
            ),
          },
          {
            key: 'isBidding' as const,
            color: 'neutral',
            tooltip: (
              <div>
                A Bidding validator is indicating its intention to participate in the next auction.{' '}
                <a
                  className="underline"
                  target="_blank"
                  href="https://docs.chainflip.io/concepts/validator-network/validator-types-and-states"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </div>
            ),
          },
          {
            key: 'isQualified' as const,
            color: 'neutral',
            tooltip: (
              <div>
                A Qualified validator can participate in auctions.{' '}
                <a
                  className="underline"
                  target="_blank"
                  href="https://docs.chainflip.io/concepts/validator-network/validator-types-and-states"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </div>
            ),
          },
          {
            key: 'isKeyholder' as const,
            color: 'neutral',
            tooltip: (
              <div>
                A Keyholder holds a fragment of an unexpired threshold signing key from a previous
                Authority Set.{' '}
                <a
                  className="underline"
                  target="_blank"
                  href="https://docs.chainflip.io/concepts/validator-network/validator-types-and-states"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </div>
            ),
          },
        ] as const
      )
        .filter(({ key }) => (keys?.includes(key) ?? true) && validator[key])
        .map(({ key, color, tooltip }) => (
          <Pill
            key={key}
            color={color}
            tooltipContent={tooltip}
            text={key.replace(/is(Current)?/, '')}
            opacity={opacity}
          />
        ))}
      {cacheValidator.isCurrentAuthority && !cacheValidator.isBidding && (
        <Tooltip
          content={
            <div>
              A Retiring (non-bidding) validator is indicating its intention to retire from the next
              auction.{' '}
              <a
                className="underline"
                target="_blank"
                href="https://docs.chainflip.io/concepts/validator-network/validator-types-and-states"
                rel="noreferrer"
              >
                Learn more
              </a>
            </div>
          }
        >
          <Pill color="red" text="Retiring" opacity={opacity} />
        </Tooltip>
      )}
    </>
  );
};
