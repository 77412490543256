export const EthereumMonochromaticLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.92209 2L9.81458 2.36542V12.9691L9.92209 13.0764L14.8442 10.167L9.92209 2Z"
      fill="white"
    />
    <path d="M9.92261 2.00012L5.00049 10.1671L9.92261 13.0766V7.92988V2.00012Z" fill="white" />
    <path
      d="M9.92301 14.6779L9.86243 14.7518V18.5291L9.92301 18.706L14.8481 11.77L9.92301 14.6779Z"
      fill="white"
    />
    <path d="M9.92212 18.7061V14.6779L5 11.77L9.92212 18.7061Z" fill="white" />
    <path d="M9.92224 13.0766L14.8443 10.1672L9.92224 7.92999V13.0766Z" fill="white" />
    <path d="M5 10.1672L9.92204 13.0767V7.92999L5 10.1672Z" fill="white" />
  </svg>
);

export const PolkadotMonochromaticLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0481 5.34193C11.5822 5.34193 12.8258 4.59382 12.8258 3.67097C12.8258 2.74812 11.5822 2 10.0481 2C8.51401 2 7.27039 2.74812 7.27039 3.67097C7.27039 4.59382 8.51401 5.34193 10.0481 5.34193Z"
      fill="white"
    />
    <path
      d="M10.0481 17.9327C11.5822 17.9327 12.8258 17.1846 12.8258 16.2617C12.8258 15.3389 11.5822 14.5908 10.0481 14.5908C8.51401 14.5908 7.27039 15.3389 7.27039 16.2617C7.27039 17.1846 8.51401 17.9327 10.0481 17.9327Z"
      fill="white"
    />
    <path
      d="M6.1771 7.65442C6.94415 6.28029 6.93917 4.79203 6.16598 4.33029C5.39279 3.86855 4.14419 4.60819 3.37714 5.98232C2.61009 7.35645 2.61507 8.84471 3.38826 9.30645C4.16145 9.76818 5.41005 9.02854 6.1771 7.65442Z"
      fill="white"
    />
    <path
      d="M16.7178 13.9495C17.4849 12.5754 17.4803 11.0874 16.7076 10.6259C15.935 10.1645 14.6868 10.9044 13.9197 12.2785C13.1527 13.6527 13.1573 15.1407 13.9299 15.6021C14.7026 16.0635 15.9508 15.3236 16.7178 13.9495Z"
      fill="white"
    />
    <path
      d="M6.16647 15.6017C6.93966 15.14 6.94464 13.6517 6.17759 12.2776C5.41054 10.9035 4.16194 10.1638 3.38875 10.6256C2.61556 11.0873 2.61058 12.5756 3.37763 13.9497C4.14468 15.3238 5.39328 16.0635 6.16647 15.6017Z"
      fill="white"
    />
    <path
      d="M16.7082 9.30779C17.4809 8.84636 17.4855 7.35835 16.7184 5.98423C15.9514 4.6101 14.7032 3.87021 13.9305 4.33163C13.1579 4.79306 13.1533 6.28107 13.9204 7.65519C14.6874 9.02932 15.9356 9.76922 16.7082 9.30779Z"
      fill="white"
    />
  </svg>
);

export const BitcoinMonochromaticLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.942 8.25085C16.1943 6.56116 14.9078 5.65282 13.1487 5.04686L13.7193 2.75796L12.3257 2.41074L11.7701 4.63932C11.4042 4.54805 11.028 4.46194 10.6542 4.37662L11.2138 2.13336L9.82129 1.78613L9.25025 4.07424C8.94708 4.00519 8.64946 3.93694 8.36057 3.86511L8.36215 3.85797L6.44072 3.3782L6.07008 4.86631C6.07008 4.86631 7.10381 5.10321 7.08199 5.1179C7.64628 5.25877 7.74866 5.63218 7.7312 5.92822L7.08119 8.53577C7.12008 8.54569 7.17048 8.55998 7.22604 8.5822L7.07921 8.54569L6.1677 12.1985C6.09865 12.3699 5.92365 12.6271 5.5292 12.5294C5.54309 12.5497 4.5165 12.2767 4.5165 12.2767L3.82483 13.8719L5.63833 14.3239C5.84307 14.3752 6.04532 14.428 6.24535 14.4802C6.37487 14.5139 6.50346 14.5475 6.63119 14.5802L6.0546 16.8957L7.44628 17.243L8.01771 14.9525C8.39747 15.0556 8.76652 15.1509 9.12763 15.2406L8.55858 17.5203L9.95184 17.8676L10.5284 15.5568C12.9042 16.0064 14.6912 15.8251 15.4423 13.6767C16.0483 11.9465 15.4126 10.9485 14.1626 10.2973C15.0729 10.0866 15.7586 9.48776 15.9416 8.25085H15.942ZM12.7581 12.7149C12.3625 14.3028 9.87643 13.6458 8.73994 13.3454L8.73984 13.3454C8.63811 13.3185 8.54719 13.2945 8.46956 13.2752L9.23464 10.2081C9.32956 10.2318 9.44552 10.2578 9.57689 10.2873C10.7526 10.5511 13.1622 11.0918 12.7585 12.7149H12.7581ZM9.81308 8.86445C10.7614 9.11743 12.8284 9.66888 13.1885 8.22634C13.5566 6.75057 11.5467 6.30577 10.565 6.08854C10.4547 6.06412 10.3573 6.04257 10.2778 6.02276L9.58412 8.80452C9.64975 8.82087 9.72679 8.84142 9.81308 8.86445Z"
      fill="white"
    />
  </svg>
);

export const ArbitrumMonochromaticLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_13120_83365)">
      <g clipPath="url(#clip1_13120_83365)">
        <path
          d="M11.2572 11.2919L10.4344 13.5494C10.414 13.6106 10.414 13.6786 10.4344 13.7466L11.8487 17.6294L13.4875 16.6842L11.5224 11.2919C11.4748 11.1695 11.3048 11.1695 11.2572 11.2919Z"
          fill="white"
        />
        <path
          d="M12.9096 7.4978C12.862 7.37541 12.692 7.37541 12.6444 7.4978L11.8216 9.75537C11.8012 9.81657 11.8012 9.88457 11.8216 9.95257L14.1403 16.3105L15.7791 15.3653L12.9096 7.5046V7.4978Z"
          fill="white"
        />
        <path
          d="M9.99877 2.55411C10.0396 2.55411 10.0804 2.56771 10.1144 2.58811L16.3567 6.19206C16.4315 6.23286 16.4723 6.31446 16.4723 6.39606V13.604C16.4723 13.6856 16.4247 13.7672 16.3567 13.808L10.1144 17.4119C10.0804 17.4323 10.0396 17.4459 9.99877 17.4459C9.95797 17.4459 9.91717 17.4323 9.88317 17.4119L3.64086 13.808C3.56606 13.7672 3.52526 13.6856 3.52526 13.604V6.38926C3.52526 6.30766 3.57286 6.22606 3.64086 6.18526L9.88317 2.58131C9.91717 2.56091 9.95797 2.54731 9.99877 2.54731V2.55411ZM9.99877 1.50012C9.77438 1.50012 9.55678 1.55452 9.35278 1.67012L3.11047 5.27407C2.70927 5.50527 2.46448 5.92686 2.46448 6.38926V13.5972C2.46448 14.0596 2.70927 14.4811 3.11047 14.7123L9.35278 18.3163C9.54998 18.4319 9.77438 18.4863 9.99877 18.4863C10.2232 18.4863 10.4408 18.4319 10.6448 18.3163L16.8871 14.7123C17.2883 14.4811 17.5331 14.0596 17.5331 13.5972V6.38926C17.5331 5.92686 17.2883 5.50527 16.8871 5.27407L10.638 1.67012C10.4408 1.55452 10.2164 1.50012 9.99197 1.50012H9.99877Z"
          fill="white"
        />
        <path
          d="M9.46868 5.87875H7.8843C7.7687 5.87875 7.65991 5.95355 7.61911 6.06235L4.22595 15.3646L5.86473 16.3098L9.60468 6.06235C9.63868 5.96715 9.57068 5.87195 9.47548 5.87195L9.46868 5.87875Z"
          fill="white"
        />
        <path
          d="M12.2429 5.87899H10.6585C10.5429 5.87899 10.4341 5.95379 10.3933 6.06259L6.51733 16.684L8.15611 17.6292L12.3721 6.06259C12.4061 5.96739 12.3381 5.87219 12.2429 5.87219V5.87899Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_13120_83365">
        <rect width="18" height="18" fill="white" transform="translate(1.00195 1)" />
      </clipPath>
      <clipPath id="clip1_13120_83365">
        <rect width="16.9998" height="16.9998" fill="white" transform="translate(1.49915 1.5)" />
      </clipPath>
    </defs>
  </svg>
);
