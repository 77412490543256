import { Switch } from '@headlessui/react';
import classNames from 'classnames';

type ToggleProps = {
  value: boolean;
  onToggle: (boolean: boolean) => void;
  disabled?: boolean;
  className?: string;
};

export default function Toggle({ value, onToggle, disabled = false, className }: ToggleProps) {
  return (
    <div>
      <Switch
        disabled={disabled}
        checked={value}
        onChange={onToggle}
        className={classNames(
          'group inline-flex h-[16px] w-[30px] rounded-full border-2 border-transparent bg-cf-gray-4 transition-colors duration-200 ease-in-out',
          !disabled && 'cursor-pointer',
          className,
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            'inline-block h-[12px] w-[12px] translate-x-0 transform rounded-full transition duration-200 ease-in-out',
            'ui-checked:translate-x-[14px] ui-checked:bg-cf-green-1 ui-checked:shadow-[0px_0px_4px] ui-checked:shadow-cf-green-1',
            disabled ? 'bg-cf-light-1' : 'bg-cf-white',
            // group-hover with ui-not-checked is not working
            !value && 'group-hover:enabled:bg-cf-white',
          )}
        />
      </Switch>
    </div>
  );
}
