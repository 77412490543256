import classNames from 'classnames';
import { WarningTriangleIcon as WarningTriangleIconLarge } from '@/shared/icons/large';
import { WarningTriangleIcon as WarningTriangleIconSmall } from '@/shared/icons/small';

const variants = (size: 'small' | 'large') => ({
  warning: {
    classes: ['bg-cf-orange-4', 'border-cf-orange-1/20', 'text-cf-orange-1'],
    Icon: size === 'small' ? WarningTriangleIconSmall : WarningTriangleIconLarge,
  },
});

const Callout = ({
  type,
  children,
  size = 'large',
  fullWidth = false,
  padding = 'thicc',
}: {
  type: keyof ReturnType<typeof variants>;
  children: React.ReactNode;
  size?: 'small' | 'large';
  fullWidth?: boolean;
  padding?: 'thin' | 'thicc';
}) => {
  const { classes, Icon } = variants(size)[type];

  return (
    <div
      className={classNames(
        'rounded-md border',
        ...classes,
        fullWidth && 'w-full',
        padding === 'thin' ? 'px-2 py-1' : 'p-4',
      )}
    >
      <div className="flex flex-row items-center space-x-1">
        <div>
          <Icon />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Callout;
